import { AnyActorRef } from "xstate";
import { actorSystem, system$$ } from "./system";
import { ActorSystemKey } from "./system-key";
import { pluckFirst, useObservable, useObservableState } from "observable-hooks";
import { distinctUntilChanged, switchMap, from, of, map } from "rxjs";
import { useMemo } from "react";
import isEqual from "fast-deep-equal";

/**
 * Use this to get an actor from the system when you know the ID.
 * The actor may or may not be in the system, so initially the value will be null.
 * If / when the actor is added to the system, the value will be updated.
 *
 * This subscribes to any changes in the actor. If we find this causes performance issues,
 * we can optimize with a selector function
 *
 * @returns [actorState, actorRef], The state can be used to access `context`, and you can send events to the `ref`
 */
export const useActorFromSystem = <R extends AnyActorRef>(id: ActorSystemKey) => {
  const id$ = useObservable(pluckFirst, [id]);
  const ref$ = useObservable(() =>
    id$.pipe(
      distinctUntilChanged(),
      switchMap((id) => system$$.pipe(map((system) => system.get(id)))),
    ),
  );
  const state$ = useObservable(() =>
    ref$.pipe(
      distinctUntilChanged(),
      switchMap((r) => (r ? from(r) : of(null))),
    ),
  );
  const ref = useObservableState(ref$, null);
  const state = useObservableState(state$, null);

  return [state, ref] as [ReturnType<R["getSnapshot"]>, R] | [null, null];
};

export const useSystem = () => {
  const system = useMemo(() => actorSystem, []);
  return {
    get: <T extends AnyActorRef>(key: ActorSystemKey) => system.get<T>(key),
  };
};

// Overload signature when defaultValue is provided
export function useSelectorFromSystem<R extends object, P>(
  id: ActorSystemKey,
  selector: (context: R) => P,
  defaultValue: P,
): P;

// Overload signature when defaultValue is not provided
export function useSelectorFromSystem<R extends object, P>(id: ActorSystemKey, selector: (context: R) => P): P | null;

export function useSelectorFromSystem<R extends object, P, D = P | null>(
  id: ActorSystemKey,
  selector: (context: R) => P, // Now takes a function that operates directly on the context
  defaultValue?: D,
) {
  const id$ = useObservable(pluckFirst, [id]);
  const ref$ = useObservable(() =>
    id$.pipe(
      distinctUntilChanged(),
      switchMap((id) => system$$.pipe(map((system) => system.get(id)))),
    ),
  );
  const state$ = useObservable(() =>
    ref$.pipe(
      distinctUntilChanged(),
      switchMap((ref) => (ref ? from(ref) : of(defaultValue))),
      map((ref) => {
        // Use the selector function directly on the context
        const context = ref?.context;
        if (!context) return defaultValue ?? null;
        const nestedState = selector(context); // Directly apply the selector to the context
        return nestedState ?? defaultValue ?? null; // Fallback to defaultValue if necessary
      }),
      distinctUntilChanged(isEqual),
    ),
  );
  // Conditional type to determine if the result should include null
  // type ResultType = D extends undefined ? P | null : P;
  type ResultType = undefined extends D ? P | null : NonNullable<D>;
  const value = useObservableState<ResultType>(state$, defaultValue as ResultType);
  return value;
}
